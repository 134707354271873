<template>
    <div class="configuracoes-pausas-page">
        <Breadcrumb titulo="CADASTROS PAUSAS" :items="item"  />

        <b-container fluid class="w-100">
            <div class="my-2 background-tabela">
                <div class="py-2 px-3">
                    <div class="mb-2 d-flex justify-content-between">
                        <b-input-group class="w-auto">
                            <b-form-input style="font-size: 14px;" v-model="pesquisa" placeholder="Pesquise..." @keyup.enter="buscarPausas(1)"></b-form-input>
                            <template #append>
                                <b-input-group-text style="cursor: pointer;">
                                    <b-icon icon="search" @click="buscarPausas(1)" />
                                </b-input-group-text>
                            </template>
                        </b-input-group>
                        <b-button style="font-size: 14px;" squared class="border-0 cor-fundo-botao" @click="show('salvar')">Nova Pausa</b-button>
                    </div>
                    <b-table striped
                             responsive
                             hover
                             order
                             :fields="fileds"
                             :items="getPausasComFiltro.Pausas"
                             class="tabela-relatorios-btable mb-2 w-0">

                        <template #cell(Acoes)="row">
                            <span class="fa fa-pen px-1 mx-1" style="cursor: pointer;" @click="show('editar', row.item)"></span>
                            <span class="fa fa-trash px-1 mx-1" style="cursor: pointer;" @click="excluirPausa(row.item.Id)"></span>
                        </template>

                    </b-table>
                    <paginacao-seta :selecionado="offset"
                                    :offset="Math.floor((getPausasComFiltro.QuantidadePausas/10.01)+1)"
                                    @eventoPaginacao="buscarPausas" />
                </div>
            </div>
        </b-container>

        <b-modal id="modal-scoped" title="Nova Pausa" ref="modal-scoped">
            <template #default>
                <b-container class="d-flex">
                    <div class="w-50 d-grid mx-2">
                        <label class="my-0" for="tipoPausa">Nome:</label>
                        <b-input-group id="tipoPausa" >
                            <b-input placeholder="Nome da Pausa..." v-model="nomePausa"></b-input>
                        </b-input-group>
                    </div>
                    <div class="mx-2 w-50">
                        <label class="my-0" for="timeLimit">Tempo Limite:</label>
                        <b-form-timepicker id="timeLimit"
                                           v-model="tempoLimite"
                                           show-seconds></b-form-timepicker>
                    </div>
                </b-container>
            </template>
            <template #modal-footer="{ cancel, ok}">
                <b-button class="border-0 bg-transparent text-dark" size="md" @click="cancel()">
                    Cancelar
                </b-button>
                <b-button size="md" variant="success" @click="salvarOuEditarPausa(ok)">
                    Salvar
                </b-button>
            </template>
        </b-modal>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import Breadcrumb from '@/components/breadcrumb/Breadcrumb.vue';
    import PaginacaoSeta from '@/components/tabelas/paginacao/PaginacaoSeta.vue';
    import PausasServices from '../../assets/js/services/PausasServices';
    export default {
        name: 'ConfiguracoesPausasPage',
        components: {
            Breadcrumb,
            PaginacaoSeta
        },
        data: function () {
            return {
                item: [
                    {
                        id: '2',
                        classe: 'fa fa-cogs',
                        texto: 'Cadastros Básicos',
                        link: '/cadastros-basicos'
                    },                   
                    {
                    id: '2',
                    classe: 'fa fa-stopwatch',
                    texto: 'Configuracoes Pausas',
                    link: '/configuracoes-pausas'
                    }
                ],
                fileds: [
                    { key: 'Nome', label: 'Nome', sortable: true },
                    { key: 'TempoLimite', label: 'Pausa Limite', sortable: true },
                    { key: 'Acoes', label: 'Ações', class: 'text-center' }
                ],
                nomePausa: '',
                tempoLimite: '',
                acao: '',
                id: '',
                pesquisa: '',
                offset: 1
            }
        },

        computed: {
            ...mapGetters({
                getPausasComFiltro: 'pausas/getPausasComFiltro'
            })
        },

        created() {
            this.buscarPausas(1);
        },

        methods: {
            ...mapActions({
                buscarPausasComFiltroStore: 'pausas/buscarPausasComFiltro',
                salvarOuEditarPausaStore: 'pausas/salvarOuEditarPausa'
            }),
            show(acao, row) {
                this.limpar();
                this.acao = acao;
                if (acao === 'editar') {
                    this.id = row.Id;
                    this.nomePausa = row.Nome;
                    this.tempoLimite = '00:' + row.TempoLimite;
                }
                this.$refs['modal-scoped'].show('modal-scoped');
            },
            async buscarPausas(selecionada) {
                var dados = {
                    'Nome': this.pesquisa,
                    'Offset': (selecionada - 1) * 10
                }
                this.buscarPausasComFiltroStore(dados);
            },
            async excluirPausa(id) {
                await PausasServices.excluirPausa(id);
                this.buscarPausasComFiltroStore(1);
            },
            async salvarOuEditarPausa(ok) {
                var dados = {
                    Id: this.id.toString(),
                    Acao: this.acao,
                    Nome: this.nomePausa,
                    TempoLimite: this.tempoLimite
                };
                this.salvarOuEditarPausaStore(dados);
                ok()
            },
            limpar() {
                this.id = '';
                this.acao = '';
                this.nomePausa = '';
                this.tempoLimite = '';
            }
        }
    }
</script>

<style scoped>
    .tabela-relatorios {
        padding: 12px;
        background-color: var(--cinza-3);
        width: 100%;
        font-size: 12px;
    }

    .configuracoes-pausas-page {
        padding-bottom: 120px;
    }

    .color_produto {
        background-color: #3925d3;
        color: #FFF !important;
    }

    .cor-fundo-botao {
        background-color: var(--cor-primaria-cliente);
    }

    .background-tabela {
        font-size: 12px;
        background-color: var(--cinza-3);
    }

    .tabela-relatorios-btable {
        background-color: #fff;
        height: calc(100vh - 230px);
        margin-bottom: 0 !important;
        font-size: 12px;
    }
</style>